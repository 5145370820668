'use strict';

var _ = require('lodash'),
    utils = require('./util'),
    sidebar = require('./sidebar');

var $cache = {};
var isNavigationOpen = false;
var enableHeaderTransparency;
var isHeaderTransparent;
var currentScrollTop;
var previousScrollTop;
var scrollPivotPosition;
var headerOffsetY;
var prevHederOffsetY;
var scrollDirection;
var headerHeight;
var $document = $(document);

function initializeCache() {
    $cache.html = $('html');
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.headerMain = $('.header-main');
    $cache.navigationItems = $('.has-submenu');
    $cache.countryContainerModal = $('#country-modal-container');
    $cache.countryLangContainer = $('#language-currency-container');
    $cache.contrastSwitcherContainer = $('.contrast-switcher');
    $cache.contrastControlsToggler = $('.toggle-contrast-controls');
    $cache.navigationListItems = $('.nav-level-1-item');
    $cache.navigationListSubmenuItems = $('.nav-level-1-link.has-submenu');
    $cache.navigationWrap = $('.nav-main-wrap');
    $cache.headerWrap = $('.header-main-wrap');
    $cache.leftSide = $('.nav-utility-item-list.left-side');
    $cache.skipToContent = $('.skip-to-content');
    $cache.sidebarToggler = $('.sidebar-toggler');
    $cache.wechat =  $('.hwp-smart-container');
    $cache.mobileSearchContainer = $cache.document.find('.mobile-search-container');
}

/**
 * Get document height
 */
function getDocumentHeight() {
    var documentBody = document.body,
        documentElement = document.documentElement;

    return Math.max(
        Math.max(documentBody.scrollHeight, documentElement.scrollHeight),
        Math.max(documentBody.offsetHeight, documentElement.offsetHeight),
        Math.max(documentBody.clientHeight, documentElement.clientHeight)
    );
}

// Expand or collapse the header & control background transparency based on scroll position
function handleHeaderVisibility() {
    if (!$cache.headerWrap.length) {
       $cache.html.addClass('no-header');
    }
    if ($cache.headerWrap.hasClass('only-logo')) {
       $cache.html.addClass('only-logo-header');
    }
    enableHeaderTransparency = $cache.headerWrap.hasClass('is-transparent');
    isHeaderTransparent = $cache.headerWrap.hasClass('is-transparent');
    currentScrollTop = $cache.window.scrollTop();
    previousScrollTop = currentScrollTop;
    scrollPivotPosition = currentScrollTop;
    headerOffsetY = 0;
    prevHederOffsetY = headerOffsetY;
    headerHeight = $cache.headerMain.height();

    $cache.window.on('scroll', function() {
        currentScrollTop = $cache.window.scrollTop();

        var scrollPosition = currentScrollTop + window.innerHeight,
            documentHeight = getDocumentHeight();

        // check if out of bounds, bottom scroll outside page on iOS (also remove 10px because of this effect)
        if (!(scrollPosition > (documentHeight - 10))) {
            if (currentScrollTop > previousScrollTop && scrollDirection !== 1) {
                scrollDirection = 1;
                scrollPivotPosition = previousScrollTop + headerOffsetY;
            } else if (currentScrollTop < previousScrollTop && scrollDirection !== -1) {
                scrollDirection = -1;
                scrollPivotPosition = previousScrollTop + headerOffsetY;
            }

            headerOffsetY = parseInt(Math.min(Math.max(scrollPivotPosition - currentScrollTop, -headerHeight), 0), 10);

            if ((prevHederOffsetY !== headerOffsetY) && !$cache.html.hasClass('menu-active') && !$cache.html.hasClass('sidebar-active')) {
                if (scrollDirection == -1 || currentScrollTop <= 0) {
                    headerOffsetY = 0;
                } else if (scrollDirection == 1 || currentScrollTop >= 0) {
                    headerOffsetY = -headerHeight;
                }

                $cache.html.toggleClass('header-collapse', headerOffsetY !== 0);

                prevHederOffsetY = headerOffsetY;
            } else if ($cache.html.hasClass('sidebar-active')) {
                // if sidebar is active remove the header collapse so on iOs we don't have scroll
                $cache.html.removeClass('header-collapse');
            }

            if (enableHeaderTransparency) {
                if (isHeaderTransparent && currentScrollTop > 0) {
                    isHeaderTransparent = false;
                    $cache.headerWrap.removeClass('is-transparent');
                } else if (!isHeaderTransparent && currentScrollTop == 0) {
                    isHeaderTransparent = true;
                    $cache.headerWrap.addClass('is-transparent');
                }
            }
    
            previousScrollTop = currentScrollTop;
        }
    });

    $cache.window.on('resize', function() {
        headerHeight = $cache.headerMain.height();
    });
}

/**
 * @description Updates the country selectors data-gtm-open attribute according to its state
 */
function handleCountrySelector() {
    $(".navigation-country-selector").on("blur", function(e) {
        $(".navigation-country-selector").attr({'data-gtm-open': false });
        $cache.html.trigger('GTM_countrySelectorClose');
    });

    $(".navigation-country-selector").on("click", function (e) {
        if ($(".navigation-country-selector").attr("data-gtm-open") === "false") {
            $(".navigation-country-selector").attr({'data-gtm-open': true });
            $cache.html.trigger('GTM_countrySelectorOpen');
        } else if ($(".navigation-country-selector").attr("data-gtm-open") === "true") {
            $(".navigation-country-selector").attr({'data-gtm-open': false });
            $cache.html.trigger('GTM_countrySelectorClose');
        }
    });

    $(".navigation-country-selector").on("keyup", function(e) {
        if (e.key === "Escape" && $(".navigation-country-selector").attr("data-gtm-open") === "true") {
            $(".navigation-country-selector").attr({'data-gtm-open': false });
            $cache.html.trigger('GTM_countrySelectorClose');
        }
    });
}

function initializeEvents() {
    handleHeaderVisibility();
    handleCountrySelector();

    $('.menu-toggle').on('click', function () {
        if (isNavigationOpen) {
            // send artificial page view
            window._uxa = window._uxa || [];
            window._uxa.push('trackPageview', window.location.pathname+window.location.hash.replace('#', '?__'));

            navigation.responsive.hide();
            $(this).attr("aria-expanded", false);
            $cache.wechat.removeClass('hide');
        } else {
            // send artificial page view
            window._uxa = window._uxa || [];
            window._uxa.push('trackPageview', window.location.pathname+window.location.hash.replace('#', '?__') + '?cs-popin-menu-mobile');
            if ($cache.html.hasClass('sidebar-active')) {
                sidebar.hide();
            }
            navigation.responsive.show();
            $(this).attr("aria-expanded", true);
            $cache.wechat.addClass('hide');
        }
        // Always sets search bars aria-expanded to false when toggling the menu
        var searchToggle = $(".search-toggle");
        searchToggle.attr("aria-expanded","false");
    });

    $('.sidebar-toggle').on('click', function (e) {
        if (isNavigationOpen) {
            navigation.responsive.hide();
        }

        var $this = $(this);
        var _sidebarMode = $this.data('sidebarMode') || 'account';

        if ($cache.html.hasClass('sidebar-active')) {
            if (_sidebarMode == 'bag') {
                window._uxa = window._uxa || [];
                window._uxa.push('trackPageview', window.location.pathname + window.location.hash.replace('#', '?__') + '?cs-popin-panier-mobile'); // open bag sidebar
            } else {
                window._uxa = window._uxa || [];
                window._uxa.push('trackPageview', window.location.pathname+window.location.hash.replace('#', '?__') + '?cs-popin-mon-espace-berluti'); // open account sidebar
            }
        } else {
            window._uxa = window._uxa || [];
            window._uxa.push('trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')); // hide any sidebars
            sidebar.hide();

            // remove header collapse when sidebar is active
            $cache.html.removeClass('header-collapse');
        }

        $cache.sidebarToggler.attr('aria-expanded', true);
        sidebar.toggleSidebar(_sidebarMode);
    });

    $cache.countryLangContainer.find('>a').on('click', function (e) {
        e.preventDefault();
        $cache.countryLangContainer.toggleClass('hover');
    });
    $cache.countryContainerModal.on('click', function() {
        $document.trigger("GTM_headerClick", "Country");
        $('#countrySelectorModal').foundation('toggle');
    });
    if ($cache.html.hasClass('no-touch')) {
        $cache.countryLangContainer.add($cache.contrastSwitcherContainer).on('mouseenter', function() {
            $(this)
                .addClass('mouseenter')
                .find('button[type="button"]')
                .attr('aria-expanded', 'true');
        });
        $cache.countryLangContainer.add($cache.contrastSwitcherContainer).find('button[type="button"]').on('click', function(b) {
            if (b.type == 'click' && !$(this).is(':hover')) {
                var $navItemContainer = $(this).closest('.nav-utility-item');
                if(!$navItemContainer.hasClass('lang-hover')) {
                    $navItemContainer
                        .addClass('lang-hover')
                        .find('button[type="button"]')
                        .attr('aria-expanded', 'true');
                } else {
                    $navItemContainer
                        .removeClass('lang-hover')
                        .find('button[type="button"]')
                        .attr('aria-expanded', 'false');
                }
            }
        });

        $cache.countryLangContainer.find('select').on('selectric-open', function(event, element, selectric) {
            $(element).parents('.selectric-wrapper').addClass('opened');
        });

    }
    $cache.countryLangContainer.add($cache.contrastSwitcherContainer).on('mouseleave',function(){
        $(this)
            .removeClass('mouseenter')
            .find('button[type="button"]')
            .attr('aria-expanded', 'false');
        if (!$cache.countryLangContainer.find('.keep-open').hasClass('select2-is-open')) {
            $cache.countryLangContainer.removeClass('hover');
        }
    });
    $cache.leftSide.on('keyup', function(e) {
        // if the target of the click isn't the container nor a descendant of the container
        if (!$cache.countryLangContainer.is(e.target) && $cache.countryLangContainer.has(e.target).length === 0) {
            $cache.countryLangContainer
                .removeClass('lang-hover hover')
                .find('button[type="button"]')
                .attr('aria-expanded', 'false');
        }
    });
    var unhoverCountryLangContainer = function() {
        if (!$cache.countryLangContainer.find('.keep-open').hasClass('select2-is-open') && !$cache.countryLangContainer.hasClass('mouseenter')) {
            $cache.countryLangContainer.removeClass('hover');
        } else {
            setTimeout(unhoverCountryLangContainer, 200);
        }
    }
    $(window).on('scroll resize', function () {
        // if (!$cache.countryLangContainer.hasClass('mouseenter')) {
        //     $cache.countryLangContainer.removeClass('hover');
        // }
        unhoverCountryLangContainer();
    });

    $cache.contrastControlsToggler.on('click touchstart keydown', function(e) {
        // 13 represents the ENTER key code; 32 represents the SPACE key code
        if ((e.type === 'keydown' && (e.keyCode === 13 || e.keyCode === 32)) || e.type === 'click' || e.type === 'touchstart') {
            e.preventDefault();
            var $this = $(this),
                $contrastControlsContainer = $this.siblings('.contrast-controls-container');

            $contrastControlsContainer.toggleClass('opened');
            $this.attr('aria-expanded', $contrastControlsContainer.hasClass('opened'));
            $(this).parent().find('.contrast-controls-container').slideToggle();
            $('.nav-main-wrap').animate({
                scrollTop: $('.nav-main').height()
            }, 400);
        }
    });

    //close contrast switcher dialog on x button
    $('.contrast-switcher-close').click(function() {
        var $contrastDropdown = $('.contrast-switcher-dropdown');

        $('.contrast-switcher').removeClass('mouseenter lang-hover');
        $contrastDropdown.hide();
        setTimeout(function() {
            $contrastDropdown.show();
        }, 500);
        $('.contrast-switcher button').focus();
    });

    // Handler function for category navigation
    function responsiveNavigationHandler(e) {
        e.preventDefault();
        var $this = $(this);

        if ($cache.navigationItems.not(this).closest('.nav-level-1-item').hasClass('active-item')) {
            navigation.responsive.hideSubNavFor($cache.navigationItems.not(this));
        }

        if ($this.closest('.nav-level-1-item').hasClass('active-item')) {
            navigation.responsive.hideSubNavFor($this);
        } else {
            navigation.responsive.showSubNavFor($this);
        }
    }

    // Toggle subpanel with second level categories if user clicks on first level category button
    function toggleDesktopNavigation(e) {
        var $this = $(this);
        var $firstLevelItem = $this.closest('.nav-level-1-item');
        var $secondLevelItem = $this.closest('.nav-level-1-link-container').next(".nav-level-2");

        if ($firstLevelItem.hasClass('hovered')) {
            navigationMouseLeaveEvents($firstLevelItem);
            if ($secondLevelItem) {
                $secondLevelItem.addClass("subpannnel-not-shown");
            }
        } else {
            navigationMouseEnterEvents($firstLevelItem);
            if ($secondLevelItem) {
                $secondLevelItem.removeClass("subpannnel-not-shown");
            }
        }
    }

    // Event handler required only if active media is below 'large'
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
        if (Foundation.MediaQuery.atLeast('large')) {
            $cache.navigationItems.off('click.mobile.navigation');
            $cache.navigationListSubmenuItems.on('click.desktop.navigation', toggleDesktopNavigation);
        } else if (!$cache.navigationItems.hasEvent('click.mobile.navigation')) {
            $cache.navigationItems.on('click.mobile.navigation', responsiveNavigationHandler);
        } else if ($cache.navigationListSubmenuItems.hasEvent('click.desktop.navigation')) {
            $cache.navigationItems.off('click.desktop.navigation');
        }
    });

    if (Foundation.MediaQuery.current !== 'large') {
        $cache.navigationItems.on('click.mobile.navigation', responsiveNavigationHandler);
    } else {
        $cache.navigationListSubmenuItems.on('click.desktop.navigation', toggleDesktopNavigation);
    }

    $('.nav-back-button').on('click', function() {
        if ($cache.activeMenuItem) {
            navigation.responsive.hideSubNavFor($cache.activeMenuItem);
        }
    });

    // Toggle a class name on the header wrapper based on category item hover state
    var hoverTimer;

    function navigationMouseEnterEvents($elem) {
        var $menuItem = $elem;
        $menuItem.addClass('hovered');
        clearTimeout(hoverTimer);
        clearTimeout($elem.hoverItemTimer);
        $cache.headerWrap.addClass('is-navigation-hovered');

        $elem.find('.nav-level-2-item').each(function (index, el) {
            var $el = $(el);
            $el.showTimer = setTimeout( function () {
                $el.addClass('showed');
            }, 75 * (index+1));
        });

        if ($elem.find('.has-submenu')) {
            $elem.find('.has-submenu').attr('aria-expanded', 'true');
        }
    }

    function navigationMouseLeaveEvents($elem) {
        var $menuItem = $elem;
        $menuItem.removeClass('hovered');
        // Delay class name removal as the user might hover over a new category item
        hoverTimer = setTimeout( function() {
            $cache.headerWrap.removeClass('is-navigation-hovered');
        }, 50);
        $elem.hoverItemTimer = setTimeout(function() {
            if (!$menuItem.hasClass('hovered')) {
                $menuItem.find('.nav-level-2-item').removeClass('showed');
            }
        }, 100);

        if ($menuItem.find('.has-submenu')) {
            $menuItem.find('.has-submenu').attr('aria-expanded', 'false');
        }

        $menuItem.find('.nav-level-1-link-container').next(".nav-level-2").removeClass("subpannnel-not-shown");
    }

    $cache.navigationListItems.on('mouseenter', function(e) {
        navigationMouseEnterEvents($(this));
    });
    $cache.navigationListItems.on('mouseleave', function(e) {
        navigationMouseLeaveEvents($(this));
    });

    // Hide subpanel and return focus to related first level category button if user click Esc on opened subpanel
    $('.nav-level-1-item > .nav-level-2').on('keydown', function(e) {
        var $this = $(this);
        var $levelOneItem= $this.closest('.nav-level-1-item');
        var $levelOneLink= $levelOneItem.find('.nav-level-1-link');

        if (e.keyCode === 27) {
            if ($levelOneLink) {
                $levelOneLink.focus();
            }
            if ($levelOneItem) {
                navigationMouseLeaveEvents($levelOneItem);
            }
        }
    });

    // Handler function for contact section hide/show from navigation
    $('.toggle-contact-details').on('click', function() {
        $(this).attr('aria-expanded') === 'false' 
            ? $(this).attr('aria-expanded', 'true') 
            : $(this).attr('aria-expanded', 'false');

        if ($(this).parent().find('.nav-contact-details__wrapper')) {
            $(this).parent().find('.nav-contact-details__wrapper').slideToggle();
        }
    });

    $cache.skipToContent.on('focusin', function() {
        $cache.html.addClass('skip-to-content-present');
    }).on('focusout', function() {
        $cache.html.removeClass('skip-to-content-present');
    });

}

function closeSearchContainer() {
    $cache.mobileSearchContainer.slideUp();
}

var navigation = {
    init: function() {
        if (!!this.initialized) {
            return;
        }

        initializeCache();
        initializeEvents();
        $cache.html.toggleClass('is-mobile', utils.isMobile());

        this.initialized = true;
    },
    show: function () {
        prevHederOffsetY = headerOffsetY = 0;
        $cache.headerMain.css('transform', 'translateZ(0) translateY(0)');
    },
    responsive: {
        // Show responsive navigation
        show: function() {
            if (!isNavigationOpen) {
                $cache.html.addClass('no-scroll menu-active');
                isNavigationOpen = true;
                closeSearchContainer();
            }
        },

        // Hide responsive navigation
        hide: function() {
            if (isNavigationOpen) {
                $cache.html.removeClass('no-scroll menu-active');
                closeSearchContainer();

                $cache.html.trigger('closeMobileMenuEvent');

                if ($cache.activeMenuItem) {
                    this.hideSubNavFor($cache.activeMenuItem);
                }

                isNavigationOpen = false;
            }
        },

        // Show sub-navigation for the passed in element
        showSubNavFor: function($el) {
            if ($el && !$cache.activeMenuItem) {
                $cache.activeMenuItem = $el;
                $el.closest('.nav-level-1-item').find('.nav-level-2').slideDown();
                $el.closest('.nav-level-1-item').addClass('active-item');
                $el.attr('aria-expanded', 'true');
                $cache.navigationWrap.addClass('submenu-is-active');
            }
        },

        // Hide active sub-navigation. Display top level categories.
        hideSubNavFor: function($el) {
            if ($el && $cache.activeMenuItem) {
                $el.closest('.nav-level-1-item').find('.nav-level-2').slideUp();
                $el.closest('.nav-level-1-item').removeClass('active-item');
                $el.attr('aria-expanded', 'false');
                $cache.navigationWrap.removeClass('submenu-is-active');
                $cache.activeMenuItem = null;
            }
        }
    }
};

module.exports = navigation;
